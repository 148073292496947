/*
===============================================================================
CSS reset, based on normalize.css v2.1.3 (now outdated)
===============================================================================
*/

/* HTML5 display definitions
------------------------------------------------------------------------------ */

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
    display: none;
    height: 0;
}

/**
 * Address `[hidden]` styling not present in IE 8/9.
 * Hide the `template` element in IE, Safari, and Firefox < 22.
 */

[hidden],
template {
    display: none;
}

/* Base
------------------------------------------------------------------------------ */

/**
 * 1. Set default font family to Arial, sans-serif.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 * 3. Adjust the font antializing for mac os x
 */
html {
    font-family: Arial, sans-serif; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -ms-text-size-adjust: 100%; /* 2 */
    -webkit-font-smoothing: antialiased; /* 3 */
    -moz-osx-font-smoothing: grayscale; /* 3 */
}

/** Setup font size so that 1rem equals 10px. */
html {
    font-size: 62.5%;
}

/**
 * 1. Remove default margin.
 * 2. Set base font size.
 */
body {
    margin: 0;
    font-size: var(--font-size-body1, 1.4rem);
}

/** Addresses `font-family` inconsistency between `textarea` and other form elements. */
html,
button,
input,
select,
textarea {
    font-family: Arial, sans-serif;
    color: var(--text-primary);
}

/**
 * Spread the element over the whole browser window.
 */
html {
    height: 100%;
}

body {
    min-height: 100%;
}

/* Links
------------------------------------------------------------------------------ */

/**
 * Remove the gray background color from active links in IE 10.
 */
a {
    background: transparent;
}

/**
 * Remove tap highlight on Webkit-based browsers
 */
input,
textarea,
button,
select,
a {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}


/* Typography
------------------------------------------------------------------------------ */

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-weight: normal;
}

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari 5, and Chrome.
 */

h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

/*
 * Address style set to `bolder` in Firefox 4+, Safari 5, and Chrome.
 */

b,
strong {
    font-weight: bold;
}

blockquote {
    margin: 1em 0 1em 40px;
}

/**
 * A better looking default horizontal rule
 */

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #c2c2c2;
    margin: 1em 0;
    padding: 0;
}

/*
 * Addresses margins set differently in IE 6/7.
 */

p,
pre {
    margin: 1em 0;
}

/*
 * Corrects font family set oddly in Safari 5 and Chrome.
 */

code,
pre {
    font-size: var(--font-size-overline, 1.0rem);
    font-family: monospace, serif;
}

/**
 * Improve readability of pre-formatted text in all browsers.
 */

pre {
    white-space: pre-wrap;
}

/**
 * Address inconsistent and variable font size in all browsers.
 */

small {
    font-size: 80%;
}

/*
 * Prevents `sub` and `sup` affecting `line-height` in all browsers.
 */

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

/* Lists
------------------------------------------------------------------------------ */

ol,
ul {
    margin: 0;
    padding: 0;
}

/*
 * Corrects list images handled incorrectly in IE 7.
 */

nav ul,
nav ol {
    list-style: none;
    list-style-image: none;
}

/* Embedded content
------------------------------------------------------------------------------ */

/*
 * 1. Removes border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improves image quality when scaled in IE 7.
 * 3. Remove the gap between images and the bottom of their containers
 */

img {
    border: 0; /* 1 */
    -ms-interpolation-mode: bicubic; /* 2 */
    vertical-align: middle; /* 3 */
    max-width: 100%;
    height: auto;
}

/*
 * Corrects overflow displayed oddly in IE 9.
 */

svg:not(:root) {
    overflow: hidden;
}

/* Figures
------------------------------------------------------------------------------ */

/*
 * Addresses margin not present in IE 8/9 and Safari 5.
 */

figure {
    margin: 0;
}

/* Forms
------------------------------------------------------------------------------ */

/*
 * Define consistent border, margin, and padding.
 */

fieldset {
    border: 1px solid var(--grey-300);
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

/*
 * Indicate that 'label' will shift focus to the associated form element
 */

label[for] {
    cursor: pointer;
}

/*
 * 1. Corrects color not being inherited in IE 8/9.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 */

legend {
    border: 0; /* 1 */
    padding: 0; /* 2 */
}

/*
 * 1. Corrects font family not being inherited in all browsers.
 * 2. Corrects font size not being inherited in all browsers.
 * 3. Addresses margins set differently in Firefox 4+, Safari 5, and Chrome
 * 4. Improves appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
    font-family: inherit; /* 1 */
    font-size: var(--font-size-input, 1.6rem); /* 2 */
    margin: 0; /* 3 */
    vertical-align: middle; /* 4 */
}

/**
 * Addresses Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
    line-height: normal;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 */
button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button; /* 2 */
    cursor: pointer; /* 3 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled],
input[disabled] {
    cursor: default;
}

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 */
input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
    -webkit-appearance: textfield; /* 1 */
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box; /* 2 */
    box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * Removes inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/*
 * 1. Removes default vertical scrollbar in IE 8/9.
 * 2. Improves readability and alignment in all browsers.
 */

textarea {
    overflow: auto; /* 1 */
    vertical-align: top; /* 2 */
}

/**
 * IE fix - hide clear button in the input - works only in IE 10+ mode
 */
input[type=text]::-ms-clear {
    display: none;
}

/**
 * Button reset
 */
button {
    border: none;
    margin: 0;
    padding: 0;
    background: transparent;
}

/* Tables
------------------------------------------------------------------------------ */

/**
 * Remove most spacing between table cells.
 */

table {
    border-collapse: collapse;
    border-spacing: 0;
}

th {
    font-weight: normal;
}

/* Iframes
------------------------------------------------------------------------------ */

iframe {
    border: none;
}

/* Others
------------------------------------------------------------------------------ */

/*
 * Remove text-shadow in selection highlight
 * These selection declarations have to be separate.
 * Customize the background color to match your design.
 */

::-moz-selection {
    background: var(--grey-a400);
    color: var(--common-white);
    text-shadow: none;
}

::selection {
    background: var(--grey-a400);
    color: var(--common-white);
    text-shadow: none;
}


/* Layout helpers
------------------------------------------------------------------------------ */
.hidden {
    display: none !important;
    visibility: hidden !important;
}

.hidden-visually {
    position: absolute !important;
    height: 1px !important;
    width: 1px !important;
    margin: -1px !important;
    padding: 0 !important;
    border: 0 !important;
    clip: rect(0,0,0,0) !important;
    font-size: 1px !important;
    overflow: hidden !important;
}

.hidden-accessible {
    position: absolute !important;
    clip: rect(1px,1px,1px,1px);
}

.invisible {
    filter: alpha(opacity=0);
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.reset {
    margin: 0;
    padding: 0;
    border: 0;
    line-height: 1.3;
    text-decoration: none;
    font-size: 100%;
    list-style: none;
}

button.round-increase,
button.round-decrease {
    box-sizing: inherit;
}

.clearfix:before, .clearfix:after {
    content: "";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    zoom: 1;
}

.zfix {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0;
    filter: Alpha(Opacity=0);
}

.prohibit-selection {
    -khtml-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none;
}

.border-box {
    box-sizing: border-box;
}

/* @todo move to RWD reset */

* {
    /* Disable font size adjustment on mobile phones.
       @see https://developer.mozilla.org/en-US/docs/Web/CSS/text-size-adjust */
    -moz-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    text-size-adjust: none;
    /* Chrome/Android uses font boost method
        - it should be disabled by this rule set or element or its parent */
    max-height: 1000000px;
}
